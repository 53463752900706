import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { collection, doc, getDocs, deleteDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import styled from "styled-components";
import { useState } from "react";
import { IsAuthenticated } from "../context/UserContext";

const iconStile = {
  position: "absolute",
  fontSize: "32px",
  color: "red",
  cursor: "pointer",
};
const Item = (props) => {
 const navigate = useNavigate()
  const [error, setError] = useState("");
  const [errorRedirect, setErrorRedirect] = useState("");

  const { user, setUser } = useContext(IsAuthenticated);
  const storage = getStorage();
  const [item, setItem] = useState();
  
  getDownloadURL(ref(storage, `${props.src}`))
  .then((url) => {
    setItem(url);
    const img = document.getElementById(props.id);
    img.setAttribute("src", item);
  })
  .catch((error) => {
    console.log(error);
  })


 


  const deleteImg = async (props) => {
    if (
      window.confirm("Сигурни ли сте че искате да изтриете тази снимка!") ==
      true
    ) {
    if (!user) {
      setError(`You have to be logged in`);
      setErrorRedirect("/auth/login");
    } else {
      const desertRef = ref(storage, `${props.gallery}/${props.itemId}.jpg`);
      deleteObject(desertRef)
        .then(() => {
          console.log("file deleted");
        })
       .then(async()=>await deleteDoc(doc(db, props.gallery, props.id)).then(() =>
       navigate("/auth/cpanel")
       )
      );
    }
  }};



  return (
    <ItemContainer>
      {user && (
        <MdDeleteForever
          onClick={() => deleteImg(props)}
          style={iconStile}
        />
      )}
      <SectionItemImg id={props.id} />
    </ItemContainer>
  );
}

const ItemContainer = styled.div`
  border-radius: 5px;
  height: auto;
  display: flex;
  width: 150px;
  margin-bottom: 10px;
  &:hover {
    transition: 0.4s;
    transition-timing-function: ease-out;
    -webkit-box-shadow: -6px 7px 19px -4px #000000;
    box-shadow: -6px 7px 19px -4px #000000;
  }
`;

const SectionItemImg = styled.img`
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
`;

export default Item;
