import React from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import { motion } from "framer-motion";
import FacebookIcon from "./addOns/FacebookIcon";
const Services = () => {
  let coll = document.getElementsByClassName("collapsible");
  let i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function () {
      this.classList.toggle("active");
      let content = this.nextElementSibling;
      if (content.style.display === "block") {
        content.style.display = "none";
      } else {
        content.style.display = "block";
      }
    });
  }
  const transitionF = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };
  return (
    <Wrapper variants={transitionF} initial="hidden" animate="show">
      <Container>
        <FacebookIcon />
        <CollapsibleContent>
          <h1>Услуги и цени</h1>
        </CollapsibleContent>
        <Collapsible trigger="Бременни">
          <CollapsibleContent>
            Бременността е онзи прекрасен период от девет вълнуващи, изпълнени с
            трепетно очакване месеци, в които жената сияе с неизмерима красота.
            Фотосесията за бременни е идеалният начин да съхраниш емоциите
            бушуващи в душата на жената и те да избликват винаги щом погледнеш
            така скъпоценните кадри. Онези мили снимки които разказват за
            трепетното очакване да зърнеш малкото същество за пръв път и да
            поемеш света в ръцете си.
            <br />
            <br />
            За направата на фотосесия за бременни няма точно определен момент,
            но разбира се най-подходящи дни са от 27 до 33 г.с. от бременността.
            Тогава бъдещата майка има все още доста енергия, а коремчето е
            високо и добре оформено. Когато резервирате час за фотосесия, ние ще
            обсъдим с вас вашите предпочитания, за това как бихте желали да
            изглежда крайния продукт, кои са вашите любими цветове и дали имате
            изисквания за аксесоари, за да можем да подберем най-подходящия
            асортимент за вашите снимки. Всяка една фотосесия е уникална сама по
            себе си и няма да видите две напълно еднакви.
            <br />
            <br />
            Фотосесията е с продължителност 45-90 минути.
            <br />
            Цена: 10 кадъра, 200 лева.
            <br />
            Цена за допълнителен кадър: 15 лв/бр.
            <br />
            Обработка на кадрите – цветова + ретуш
          </CollapsibleContent>
        </Collapsible>
        <Collapsible trigger="Новородени">
          <CollapsibleContent>
            Да държиш новородено бебче в ръцете си е цялото щастие на света.
            Фотосесиите за новородени бебета до 15 дни са един изключителен
            начин, по който да съхраниш тези скъпоценни мигове. Периодът за
            снимки до „15 дни“ не е случаен. През тези първи дни след раждането
            бебчето все още спи по 20-23 часа в денонощие, коликите не са
            започнали, а то с лекота заема вътреутробните пози, в които го
            поставяме за снимка. Този тип фотосесии са колкото красиви, толкова
            и трудни за всеки фотограф, защото той трябва да намери идеалния
            баланс на топлина, тишина и уют в студиото, за да предразположи,
            както родителите така и малкия човек, а позите се ограничават до
            легнали. Ако решите да направите фотосесия за вашето новородено бебе
            е добре да се свържете с мен още преди да е започнало раждането, за
            да можем да уговорим детайлите около самите снимки. Както при
            другите фотосесия, така и за тази се подбират индивидуално аксесоари
            и се подреждат декори, за да може снимките да бъдат уникални и
            неповторими, съобразени с вашите предпочитания. Ние държим
            изключително много на хигиената и за това всички материи които
            използваме са хипоалергенни и изпрани и изгладени непосредствено
            преди заснемане на фотосесията. Фотосесия за новородени може да бъде
            реализирана и с малко по-големи деца от 2 до 6 месеца, но тогава
            както вече споменахме, позите стават доста ограничени, защото детето
            е започнало да развива своята моторика и не винаги успяваме да го
            задържим в идеалната поза за снимка, а будуването по време на
            фотосесия понякога е проблем. Но винаги можем да Ви консултираме
            предварително и да подберем най-подходящия тип сесия за вас и вашето
            дете.
            <br />
            <br />
            Фотосесията е с продължтелност около 3 часа.
            <br />
            Цена: 10 кадъра, 250 лева.
            <br />
            Цена за допълнителен кадър: 20 лв/бр.
            <br />
            Обработка на кадрите – цветова + ретуш
          </CollapsibleContent>
        </Collapsible>
        <Collapsible trigger="Smash Cake">
          <CollapsibleContent>
            Smash cake фотосесията или разбиване на торта, е уникален и забавен
            начин да отбележиш първата годинка от раждането на своето вече
            пораснало мъниче. Снимките ще носят радост и мили спомени години
            наред. Важното при този тип фотосесия е избора на тортата. Ние
            съветваме родителите да избират торта с бели блатове, защото колкото
            и красива и вкусна да е шоколадовата торта, размазана по декора и
            лицето на детето Ви не изглежда толкова привлекателна. Бели блатове
            и лек крем с малко цвят е идеалният вариант. Вие избирате тортата, а
            ние правим съвършения за събитието декор. Той може да включва
            балони, цветя и всякакво украса, която ще обсъдим и подберем заедно.
            Както не веднъж казахме, всяка фотосесия е индивидуална, а декорите
            винаги са уникални и подбрани специално за Вас. Самата фотосесия
            започва с индивидуални снимки на рожденика, преоблечен в тоалетите,
            които родителите са подготвили за случая и завършва с момента на
            разбиване на тортата.
            <br />
            <br />
            Цена: 150 лева.
            <br />
            Ваничка с мляко се заплаща допълнително + 50 лева.
            <br />
            В посочената цена са включени 50 сполучливи кадри.
            <br />
            Обработка на кадрите - цветова
          </CollapsibleContent>
        </Collapsible>
        <Collapsible trigger="Кръщене">
          <CollapsibleContent>
            Фотосесията включва заснемане на целия ритуал и общи снимки с
            гостите след това в двора на църквата. <br />
            Цена: 200 лева.
            <br />
            В посочената цена са включени всички сполучливи кадри.
            <br />
            <i>
              *Снимките в ресторант за заплащат допълнително 120 лева до 1 час
              ангажираност. Всеки следващ започнал час: 120 лева.{" "}
            </i>
            <br />
            Обработка на кадрите - цветова
            <br />
          </CollapsibleContent>
        </Collapsible>
        <Collapsible trigger="Детски рожден ден">
          <CollapsibleContent>
            Репортажно отрзяване на събитието
            <br />
            Цена: 120 лева до 1 час ангажираност.
            <br />
            Всеки следващ започнал час: 120 лева.
            <br />
            В посочената цена са включени всички сполучливи кадри.
            <br />
            Обработка на кадрите - цветова
            <br />
          </CollapsibleContent>
        </Collapsible>
        <Collapsible trigger="Индивидуална фотосесия в студио/ на открито">
          <CollapsibleContent>
            Фотосесията е с продължителност 40 – 60 мин.
            <br />
            Цена: 10 кадъра, 120 лева.
            <br />
            Цена за допълнителен кадър: 10 лв/бр.
            <br />
            Обработка на кадрите – цветова + ретуш
          </CollapsibleContent>
        </Collapsible>
        <Collapsible trigger="Абитуриентски бал">
          <CollapsibleContent>
            Цена: Индивидуално според спецификата на ангажимента.
            <br />В посочената цена са включени всички сполучливи кадри.
          </CollapsibleContent>
        </Collapsible>
        <Collapsible trigger="Семейна фотосесия">
          <CollapsibleContent>
            Фотосесията е с продължителност 40 – 60 мин.
            <br />
            Цена за всички сполучливи кадри: 150 лева.
            <br />
            Обработка на кадрите – цветова
          </CollapsibleContent>
        </Collapsible>
        <Container>
          <CollapsibleContent>
            <h1>Допълнителни условия</h1>
            <p>
              <i>
                В срок до 5 работни дни след фотосесията ще получите линк към
                галерия за подбор на кадрите. След потвърждение от ваша страна,
                срокът за предаване на снимките е до 20 работни дни, чрез линк
                за изтегляне. Необработени и незаплатени кадри не се предават и
                се изтриват.
              </i>
            </p>
          </CollapsibleContent>
        </Container>
      </Container>
    </Wrapper>
  );
};
const Container = styled.div`
  max-width: 700px;
  background-color: #ffffff;
`;
const Wrapper = styled(motion.div)`
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  min-height: calc(100vh - 50px);
  background: rgb(228, 193, 176);
  background: radial-gradient(
    circle,
    rgba(228, 193, 176, 0.6703723725818452) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;
const CollapsibleContent = styled.p`
  width: 100%;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
`;

export default Services;
