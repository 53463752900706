import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Error from "./Error";
import { db } from "./utils/firebase";
import { collection, addDoc } from "firebase/firestore";
import { BsFacebook } from "react-icons/bs";
import Footer from "./Footer";

const iconStile = {
  fontSize: "25px",
  marginLeft: "15px",
  color: "#8a8a8a",
  cursor: "pointer",
};

const iFrameStyle = {
  width: "100%",
  height: "300px",
  style: "border:0;",
  allowfullscreen: "",
  loading: "lazy",
};

const Contact = (props) => {
  const [error, setError] = useState("");
  const [errorRedirect, setErrorRedirect] = useState("");
  const transitionF = props.transitionF;
  const [popUp, setPopUp] = useState(false);

  useEffect(() => {}, [popUp]);

  async function AddData(e) {
    console.log("kk");
    e.preventDefault();
    let customerNames = e.target.customerNames.value;
    let email = e.target.email.value;
    let message = e.target.message.value;

    try {
      const docRef = await addDoc(collection(db, "messages"), {
        customerNames: customerNames,
        email: email,
        message: message,
        date: new Date().toString(),
      });
      console.log("Document written successfully");
      setPopUp(true);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  return (
    <Wrapper variants={transitionF} initial="hidden" animate="show">
      <ContactFormWrapper>
        {!popUp && (
          <form onSubmit={AddData}>
            <h2>Може да се свържете с мен чрез:</h2>
            <Heading>
              Е-mail: <i>gogophotos2021@gmail.com</i>{" "}
            </Heading>
            <Heading>
              Телефон: <i><a href="tel:+359885226710">+359885226710</a></i>{" "}
            </Heading>

            <Heading>
              Последвайте ме във:{" "}
              <BsFacebook
                style={iconStile}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/Go-Go-Photos-112824747719735/",
                    "_blank"
                  )
                }
              />
            </Heading>
            <iframe
              style={iFrameStyle}
              src={
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d727.0210144872666!2d23.555901918771603!3d43.20772623986194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ab1932247b9fb5%3A0xfbf2b5eb5425f45c!2sGo-Go%20Photos!5e0!3m2!1sbg!2sbg!4v1643199215045!5m2!1sbg!2sbg"
              }
            ></iframe>
            <br />
            <h1>Форма за контакт</h1>
            <InputContainer>
              <NavTitle>
                <label htmlFor="item_title">Име и фамилия: </label>
              </NavTitle>
              <Input
                type="text"
                placeholder="Вашите имена..."
                name="customerNames"
                required
              />
            </InputContainer>
            <InputContainer>
              <NavTitle>
                <label htmlFor="item_title">E-mail: </label>
              </NavTitle>
              <Input
                type="email"
                placeholder="E-mail"
                defaultValue=""
                name="email"
                required
              />
            </InputContainer>
            <InputContainer>
              <NavTitle>
                <label htmlFor="item_description">Съобщение:</label>
              </NavTitle>
              <TextArea
                required
                placeholder="Вашето съобщение..."
                name="message"
                defaultValue=""
              ></TextArea>
            </InputContainer>
            <InputContainer>
              <NavTitle>
                <label htmlFor=""></label>
              </NavTitle>
              <SubmitButton type="submit" value="Изпрати" />
            </InputContainer>
            <Error error={error} errorRedirect={errorRedirect} />
          </form>
        )}
        {popUp && (
          <Success>
            Съобщението е изпратено успешно! <br /> Благодаря Ви!
          </Success>
        )}
      </ContactFormWrapper>
      <Footer />
    </Wrapper>
  );
};

const Success = styled.div`
  padding: 20px;
  border: 2px solid #04aa6d;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  transition: opacity 0.6s;
  margin-bottom: 15px;
  margin-top: 20px;
`;
const Wrapper = styled(motion.div)`
  margin-top: 60px;
  min-height: calc(100vh - 90px);
  background: rgb(228, 193, 176);
  background: radial-gradient(
    circle,
    rgba(228, 193, 176, 0.6703723725818452) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;
const ContactFormWrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;
  background-color: #f2f2f2;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
`;

const Heading = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;

const InputContainer = styled(motion.div)`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 5px;
  min-width: 200px;
  padding: 10px;
  width: 500px;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Input = styled.input`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  padding: 20px;
`;
const TextArea = styled.textarea`
  display: flex;
  justify-content: center;

  height: 150px;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #b8b8b8;
`;

const NavTitle = styled.span`
  display: flex;
  font-size: 16px;
  width: 90%;
  margin-right: 5px;
  align-items: center;
  padding-bottom: 5px;
`;
const SubmitButton = styled.input`
  background-color: #ffffff;
  border: 1px solid #b8b8b8;
  display: inline-block;
  cursor: pointer;
  color: #39393f;
  min-width: 200px;
  height: 40px;
  font-size: 16px;
  padding: 5px;
  height: 40px;
  transition: 0.5s;
  &:hover {
    transition: 0.5s;
    color: #ffffff;
    background-color: lightblue;
    background-color: #39393f;
  }
`;

export default Contact;
