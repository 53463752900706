import React, { useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { IsAuthenticated } from "../context/UserContext";
const AuthNav = () => {
  const { user } = useContext(IsAuthenticated);
  const navigate = useNavigate();
  return (
    <Container>
      {user ? (
        <>
          <Button onClick={() => navigate("/auth/cpanel")}>C-panel</Button>
          <Button onClick={() => navigate("/add-image")}>Добави снимка</Button>
          <Button onClick={() => navigate("/all-items")}>Всички снимки</Button>
          <Button onClick={() => navigate("/auth/messages")}>Съобщения</Button>
          <Button onClick={() => navigate("/auth/signOut")}>Sign out</Button>
        </>
      ) : (
        <Button onClick={() => navigate("/auth/login")}>Log in</Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
`;
const Button = styled.button`
  background-color: #ffffff;
  border: 1px solid #000000;
  display: inline-block;
  cursor: pointer;
  color: #39393f;
  min-width: 200px;
  height: 40px;
  font-size: 16px;
  padding: 5px;
  transition: 0.5s;
  margin-left: 5px;
  &:hover {
    transition: 0.5s;
    color: #ffffff;
    background-color: lightblue;
    background-color: #39393f;
  }
`;
export default AuthNav;
