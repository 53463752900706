import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { BsFacebook } from "react-icons/bs";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Img1 from "../images/home/1.jpg";
import Img2 from "../images/home/2.JPG";
import Img3 from "../images/home/3.jpg";
import Img4 from "../images/home/4.jpg";
import Img5 from "../images/home/5.jpg";
import Img6 from "../images/home/6.jpg";

const Home = (props) => {
  const transitionF = props.transitionF;
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  return (
    <Wrapper variants={transitionF} initial="hidden" animate="show">
      ;
      <div className="slide-container">
        <Fade
          autoplay={true}
          pauseOnHover={false}
          indicators={false}
          arrows={false}
          duration={2000}
        >
          <Image src={Img1} />
          <Image src={Img2} />
          <Image src={Img3} />
          <Image src={Img4} />
          <Image src={Img5} />
          <Image src={Img6} />
        </Fade>
      </div>
      <IconCotainer
        onClick={() =>
          window.open(
            "https://www.facebook.com/Go-Go-Photos-112824747719735/",
            "_blank"
          )
        }
      >
        <BsFacebook />
      </IconCotainer>
    </Wrapper>
  );
};
const Wrapper = styled(motion.div)`
  height: calc(100vh);
`;

const Image = styled.img`
  width: 100vw;
  display: flex;
  object-fit: cover;
  height: calc(100vh - 20px);
  @media (max-width: 768px) {
    object-fit: contain;
    width: 100vw;
  }
`;

const IconCotainer = styled.div`
  position: absolute;
  font-size: 50px;
  z-index: 999;
  right: 0;
  top: 45%;
  opacity: 0.8;
  margin-right: 20px;
  color: #8a8a8a;
  cursor: pointer;
  transition: transform 250ms, opacity 400ms;
  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
`;

export default Home;
