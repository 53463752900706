import { BsFacebook } from "react-icons/bs";
import styled from "styled-components";
import { Link } from "react-router-dom";

const FacebookIcon = () => {
  return (
    <IconCotainer
      onClick={() =>
        window.open(
          "https://www.facebook.com/Go-Go-Photos-112824747719735/",
          "_blank"
        )
      }
    >
      <BsFacebook />
    </IconCotainer>
  );
};

export default FacebookIcon;
const IconCotainer = styled.div`
  position: absolute;
  font-size: 50px;
  z-index: 999;
  right: 0;
  top: 45%;
  opacity: 0.8;
  margin-right: 20px;
  color: #8a8a8a;
  cursor: pointer;
  transition: transform 250ms, opacity 400ms;
  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
