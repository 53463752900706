import React, { useState } from "react";
import styled from "styled-components";

import { NavLink } from "react-router-dom";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <Burger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <div />
        <div />
        <div />
      </Burger>
      <Logo id="Logo" isOpen={isOpen}>
        <StyledLink
          onClick={() => setIsOpen(!isOpen)}
          to="/"
          exact="true"
          className="main-nav"
        >
          Начало
        </StyledLink>

        <StyledLink
          onClick={() => setIsOpen(!isOpen)}
          to="/gallery"
          exact="true"
          style={(isActive) => ({
            color: isActive ? "red" : "geen",
          })}
        >
          Портфолио
        </StyledLink>
        <StyledLinkLogo
          onClick={() => setIsOpen(!isOpen)}
          to="/"
          exact="true"
          className="main-nav"
        >
          Go-Go Photos
        </StyledLinkLogo>
        <StyledLink
          onClick={() => setIsOpen(!isOpen)}
          to="/services"
          exact="true"
        >
          Услуги и цени
        </StyledLink>
        <StyledLink
          onClick={() => setIsOpen(!isOpen)}
          to="/contact"
          exact="true"
        >
          Контакти
        </StyledLink>
      </Logo>
    </Container>
  );
};

const Burger = styled.div`
  width: 30px;
  height: 30px;
  justify-content: space-around;
  flex-flow: column nowrap;
  margin-right: 10px;
  display: none;
  cursor: pointer;
  @media (max-width: 768px) {
    display: flex;
    margin-left: 10px;
  }
  div {
    width: 30px;
    height: 4px;
    background-color: ${({ isOpen }) => (isOpen ? `#cccccc` : `#cccccc`)};
    border-radius: 2px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ isOpen }) => (isOpen ? `rotate(45deg)` : `rotate(0)`)};
    }
    &:nth-child(2) {
      transform: ${({ isOpen }) =>
        isOpen ? `translateX(100%)` : `translateX(0)`};
      opacity: ${({ isOpen }) => (isOpen ? `0` : `1`)};
    }
    &:nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? `rotate(-45deg)` : `rotate(0)`)};
    }
  }
`;
const StyledLinkLogo = styled.div`
  text-decoration: none;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  font-family: "Beau Rivage", cursive;
  font-size: 34px;
  font-weight: 100;

  color: #2a2a2a;
  margin-left: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  display: inline-block;
  padding: 20px 20px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #2a2a2a;

  margin-left: 10px;

  &.active {
    background: rgb(244, 220, 206);
    background: radial-gradient(
      circle,
      rgba(244, 220, 206, 1) 0%,
      rgba(255, 255, 255, 0.30062447342218135) 100%
    );
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 10px;
    content: "";
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    background: rgb(244, 220, 206);
    background: radial-gradient(
      circle,
      rgba(244, 220, 206, 1) 0%,
      rgba(255, 255, 255, 0.30062447342218135) 100%
    );
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after {
    width: 100%;
    left: 0;
  }

  @media (max-width: 768px) {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    top: 49px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 10px;
    transition: all 0.5s ease-in-out;
    transform: ${({ isOpen }) =>
      isOpen ? `translateX(0px)` : `translateX(100%)`};
  }
`;

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  z-index: 999;
  justify-content: space-around;
  width: 100%;
  height: 60px;
  background-color: #ffffff;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export default Nav;
