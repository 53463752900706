import React, { useState, useEffect } from "react";

import "./App.css";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  NavLink,
} from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./components/Home";
import Gallery from "./components/gallery/Gallery";
import Pregnant from "./components/gallery/Pregnant";
import NewBorn from "./components/gallery/NewBorn";
import SmashCake from "./components/gallery/SmashCake";
import Christening from "./components/gallery/Christening";
import Birthday from "./components/gallery/Birthday";
import Personal from "./components/gallery/Personal";
import Prom from "./components/gallery/Prom";
import Family from "./components/gallery/Family";

import Contact from "./components/Contact";
import Services from "./components/Services";
import GlobalStyle from "./components/GlobalStyle";
import Login from "./components/auth/LoginPage";
import CPanel from "./components/auth/CPanel";

import Messages from "./components/content/Messages";
import SignOut from "./components/auth/SignOut";
import { IsAuthenticated } from "./components/context/UserContext";
import { auth } from "./components/utils/firebase";

import AllItems from "./components/content/AllItems";
import AddItem from "./components/content/AddItem";
import ItemDetailed from "./components/content/ItemDetailed";

function App() {
  const transitionF = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };
  const [user, setUser] = useState({});
  useEffect(() => {
    auth.onAuthStateChanged(setUser);
  }, [user]);

  return (
    <IsAuthenticated.Provider value={{ user, setUser }}>
      <Router>
        <Nav />
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signOut" element={<SignOut />} />
          <Route path="/auth/cpanel" element={<CPanel />} />
          <Route path="/auth/messages" element={<Messages />} />
          <Route path="/" element={<Home transitionF={transitionF} />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/pregnant" element={<Pregnant />} />
          <Route path="/gallery/newborn" element={<NewBorn />} />
          <Route path="/gallery/smashcake" element={<SmashCake />} />
          <Route path="/gallery/christening" element={<Christening />} />
          <Route path="/gallery/birthday" element={<Birthday />} />
          <Route path="/gallery/personal" element={<Personal />} />
          <Route path="/gallery/prom" element={<Prom />} />
          <Route path="/gallery/family" element={<Family />} />
          <Route
            path="/contact"
            element={<Contact transitionF={transitionF} />}
          />
          <Route path="/all-items" element={<AllItems user={user} />} />

          <Route path="/items/item-detailed/:id" element={<ItemDetailed />} />
          <Route path="/add-image" element={<AddItem user={user} />} />
        </Routes>
      </Router>

      <GlobalStyle />
    </IsAuthenticated.Provider>
  );
}

export default App;
