import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Gallery from "react-photo-gallery";
import { db } from "../utils/firebase";
import { collection, getDocs } from "firebase/firestore";
import Carousel, { Modal, ModalGateway } from "react-images";

const Birthday = () => {
  //LIGHTBOX
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  //

  const itemsCollectionRef = collection(db, "birthday");
  const [items, setItems] = useState([{}]);
  useEffect(() => {
    const getItems = async () => {
      const setDataAll = await getDocs(itemsCollectionRef);
      setItems(
        setDataAll.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        }))
      );
    };
    getItems();
  }, []);

  console.log(items);
  return (
    <Container>
      <Gallery photos={items} direction={"column"} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={items.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Container>
  );
};
const Container = styled.div`
  margin-top: 50px;
`;
export default Birthday;
