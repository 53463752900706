import { useState, useEffect } from "react";
import { db } from "../utils/firebase";
import { getDoc, doc } from "firebase/firestore";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";

import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Link } from "react-router-dom";

import { getAuth } from "firebase/auth";

const ItemDetailed = (props) => {
  const location = useLocation();
  const { gallery } = location.state;

  const auth = getAuth();
  const user = auth.currentUser;

  const [item, setItem] = useState({});

  const [imgUrl, setImgUrl] = useState("");
  const { id } = useParams();

  const [docRef, setDocRef] = useState(doc(db, gallery, id));
  const storage = getStorage();

  console.log(props);
  getDownloadURL(ref(storage, `${item.src}`))
    .then((url) => {
      setImgUrl(url);
    })
    .catch((error) => {
      // Handle any errors
    });

  useEffect(() => {
    const getItem = async () => {
      const data = await getDoc(docRef);
      console.log(data.data().item_authorId);

      setItem(data.data());
    };
    getItem();
  }, []);

  console.log(item);
  //console.log(item.item_authorId, userId);

  console.log(item);

  return (
    <>
      <ItemContainer>
        <SubContainerTop>
          <SectionItemImg alt="item-image" src={imgUrl} id="myImg" />
        </SubContainerTop>

        <HR />
        <SubContainer>
          {user && (
            <Link to={`/items/delete-item/${id}`} state={{ gallery: gallery }}>
              <SubmitButton type="button">Delete item </SubmitButton>
            </Link>
          )}
        </SubContainer>
      </ItemContainer>
    </>
  );
};

const ItemContainer = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  max-width: 600px;
  min-width: 100px;
  object-fit: cover;
  padding-bottom: 15px;
  margin: 0 auto;
  margin-top: 5px;
  -webkit-box-shadow: -6px 7px 19px -4px #000000;
  box-shadow: -6px 7px 19px -4px #000000;
`;
const SectionItemImg = styled.img`
  display: flex;
  max-width: 600px;
  min-width: 100px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: auto;
  margin-bottom: 20px;
  object-fit: cover;
`;
const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
`;
const SubContainerTop = styled.div`
  display: flex;
  justify-content: right;
`;

const HR = styled.div`
  border-bottom: 1px solid gray;
  margin: 3px;
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const SubmitButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #000000;
  display: inline-block;
  width: 150px;
  cursor: pointer;
  color: #39393f;
  height: 40px;
  font-size: 16px;
  padding: 5px;
  height: 40px;
  transition: 0.5s;
  &:hover {
    transition: 0.5s;
    color: #ffffff;
    background-color: lightblue;
    background-color: #39393f;
  }
`;
export default ItemDetailed;
