import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


html {
    
  font-family: "Montserrat", sans-serif;
}
html,body{margin:0;padding:0;}
h1,p{margin:0 0 1em}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
body {
  margin: 0;
  padding: 0;
}
`;

export default GlobalStyle;
