import React, { useState, useEffect, useContext } from "react";
import { db } from "../utils/firebase";
import { collection, doc, getDocs, deleteDoc } from "firebase/firestore";
import styled from "styled-components";
import { MdDeleteForever } from "react-icons/md";
import AuthNav from "../auth/AuthNav";
import { IsAuthenticated } from "../context/UserContext";
const Messages = () => {
  const { user } = useContext(IsAuthenticated);
  const messagesRef = collection(db, "messages");
  const [messages, setMessages] = useState([]);
  const getMessages = async () => {
    const setDataAll = await getDocs(messagesRef);
    setMessages(setDataAll.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  useEffect(() => {
    getMessages();
  }, []);

  const deleteMessage = async (props) => {
    if (
      window.confirm("Сигурни ли сте че искате да изтриете това съобщение!") ===
      true
    ) {
      await deleteDoc(doc(db, "messages", props.id)).then(() => getMessages());
    }
  };
  const iconStile = {
    fontSize: "32px",
    color: "gray",
    cursor: "pointer",
  };
  return (
    <ItemsContainer>
      <AuthNav />
      {user &&
      <Table>
        <thead key="thead">
          <tr>
            <th>Дата/Час</th>
            <th>Имена</th>
            <th>Email</th>
            <th>Съобщение</th>
            <th>Изтрий</th>
          </tr>
        </thead>
        <tbody key="tbody">
          {messages.map((message) => {
            return (
              <tr>
                <td>{message.date.toLocaleString().substring(0, 25)}</td>
                <td>{message.customerNames}</td>
                <td>{message.email}</td>
                <td>{message.message}</td>
                <td>
                  <MdDeleteForever
                    onClick={() => deleteMessage(message)}
                    style={iconStile}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      }
    </ItemsContainer>
  );
};
const ItemsContainer = styled.div`
  max-width: 1200px;
  min-height: calc(100vh - 90px);
  margin: 0 auto;
  margin-top: 60px;
  flex-wrap: wrap;
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #04aa6d;
    color: white;
    border: 1px solid #ddd;
    padding: 8px;
  }
  tr {
    &:hover {
      background-color: #ddd;
    }
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
`;


export default Messages;
