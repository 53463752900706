import React, { useState } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Container>
      <Copy>&copy; Copyright 2022 Georgi Kovachev Photographer</Copy>
    </Container>
  );
};

const Copy = styled.div`
  text-decoration: none;
  display: inline-block;
  padding: 4px 10px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 200;
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background-color: #8a8a8a;
`;

export default Footer;
