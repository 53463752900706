import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { IsAuthenticated } from "../context/UserContext";
import styled from "styled-components";
import AuthNav from "../auth/AuthNav";
const CPanel = () => {
  const { user } = useContext(IsAuthenticated);

  return (
    <Container>
      <AuthNav />
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  height: 200px;
  min-height: calc(100vh - 80px);
`;

export default CPanel;
