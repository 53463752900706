import React, {useState, useEffect, useContext} from "react";
import { db } from "../utils/firebase";
import { collection, getDocs } from "firebase/firestore";
import Item from "./Item";
import styled from "styled-components";
import { motion } from "framer-motion";
import AuthNav from "../auth/AuthNav";
import { IsAuthenticated } from "../context/UserContext";
const AllItems = () => {
  const { user } = useContext(IsAuthenticated);
  const PregnantCollectionRef = collection(db, "pregnant");
  const NewBornCollectionRef = collection(db, "newborn");
  const smashcakeCollectionRef = collection(db, "smashcake");
  const christeningCollectionRef = collection(db, "christening");
  const birthdayCollectionRef = collection(db, "birthday");
  const personalCollectionRef = collection(db, "personal");
  const promCollectionRef = collection(db, "prom");
  const familyCollectionRef = collection(db, "family");
  const [pregnant, setPregnant] = useState([]);
  const [newBorn, setNewBorn] = useState([]);
  const [smashCake, setSmashCake] = useState([]);
  const [christening, setChristening] = useState([]);
  const [birthday, setBirthday] = useState([]);
  const [personal, setPersonal] = useState([]);
  const [prom, setProm] = useState([]);
  const [family, setFamily] = useState([]);
  useEffect(() => {
    const getAll = async () => {
      const setDataAllPregnant = await getDocs(PregnantCollectionRef);
      const setDataAllNewBorn = await getDocs(NewBornCollectionRef);
      const setDataAllSmashCake = await getDocs(smashcakeCollectionRef);
      const setDataAllChristening = await getDocs(christeningCollectionRef);
      const setDataAllBirthday = await getDocs(birthdayCollectionRef);
      const setDataAllPersonal = await getDocs(personalCollectionRef);
      const setDataAllProm = await getDocs(promCollectionRef);
      const setDataAllFamily = await getDocs(familyCollectionRef);
      setPregnant(
        setDataAllPregnant.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setNewBorn(
        setDataAllNewBorn.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setSmashCake(
        setDataAllSmashCake.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setChristening(
        setDataAllChristening.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setBirthday(
        setDataAllBirthday.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setPersonal(
        setDataAllPersonal.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setProm(
        setDataAllProm.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setFamily(
        setDataAllFamily.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getAll();
  }, []);

  return (
    <Container>
      <AuthNav />
      {user &&
      <>
      <h1>Бременни</h1>
      <ItemsContainer>
        {pregnant.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      <HR />
      <h1>Новородени</h1>
      <ItemsContainer>
        {newBorn.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      <HR />
      <h1>Smashcake</h1>
      <ItemsContainer>
        {smashCake.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      <HR />
      <h1>Кръщене</h1>
      <ItemsContainer>
        {christening.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      <HR />
      <h1>Детски рожден ден</h1>
      <ItemsContainer>
        {birthday.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      <HR />
      <h1>Индивидуална фотосесия</h1>
      <ItemsContainer>
        {personal.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      <HR />
      <h1>Абитуриентски бал</h1>
      <ItemsContainer>
        {prom.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      <HR />
      <h1>Семейна фотосесия</h1>
      <ItemsContainer>
        {family.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      </>}
    </Container>
  );
};
const HR = styled.div`
  border-bottom: 1px solid gray;
  margin: 3px;
  opacity: 0.7;
  width: 100%;
`;
const ItemsContainer = styled(motion.div)`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Container = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;
export default AllItems;
