import React from "react";
import styled from "styled-components";
import newBorn from "../../images/galleryHeader/newBorn.jpg";
import pregnant from "../../images/galleryHeader/pregnant.jpg";
import smashCake from "../../images/galleryHeader/smashCake.jpg";
import christening from "../../images/galleryHeader/christening.jpg";
import birthday from "../../images/galleryHeader/birthday.jpg";
import personal from "../../images/galleryHeader/personal.jpg";
import family from "../../images/galleryHeader/family.jpg";
import prom from "../../images/galleryHeader/prom.jpeg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import FacebookIcon from "../addOns/FacebookIcon";

const Gallery = () => {
  const transitionF = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  return (
    <Wrapper>
      <Container variants={transitionF} initial="hidden" animate="show">
        <FacebookIcon />
        <Link to={`/gallery/pregnant`}>
          <ContainerPortfolio>
            <Title>Бременни</Title>
            <ImageInContainer src={pregnant} />
          </ContainerPortfolio>
        </Link>
        <Link to={`/gallery/newborn`}>
          <ContainerPortfolio>
            <Title>Новородени</Title>
            <ImageInContainer src={newBorn} />
          </ContainerPortfolio>
        </Link>
        <Link to={`/gallery/smashCake`}>
          <ContainerPortfolio>
            <Title>Smash cake</Title>
            <ImageInContainer src={smashCake} />
          </ContainerPortfolio>
        </Link>
        <Link to={`/gallery/christening`}>
          <ContainerPortfolio>
            <Title>Кръщене</Title>
            <ImageInContainer src={christening} />
          </ContainerPortfolio>
        </Link>
        <Link to={`/gallery/birthday`}>
          <ContainerPortfolio>
            <Title>Детски рожден ден</Title>
            <ImageInContainer src={birthday} />
          </ContainerPortfolio>
        </Link>
        <Link to={`/gallery/personal`}>
          <ContainerPortfolio>
            <Title>Индивидуална фотосесия</Title>
            <ImageInContainer src={personal} />
          </ContainerPortfolio>
        </Link>
        <Link to={`/gallery/family`}>
          <ContainerPortfolio>
            <Title>Семейна фотосесия</Title>
            <ImageInContainer src={family} />
          </ContainerPortfolio>
        </Link>
        <Link to={`/gallery/prom`}>
          <ContainerPortfolio>
            <Title>Абитуриентски бал</Title>
            <ImageInContainer src={prom} />
          </ContainerPortfolio>
        </Link>
       
          <EmptyContainer>
            
          </EmptyContainer>
        
      </Container>
    </Wrapper>
  );
};

const Title = styled(motion.h2)`
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ImageInContainer = styled.img`
  object-fit: cover;
  max-width: 100%;
  width: 250px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  @media (max-width: 768px) {
    margin-top: 0px;
    max-width: 100%;  
  }
`;

const ContainerPortfolio = styled.div`
  background-color: #ffffff;
  width: 250px;
  display: flex;
  flex-direction: column;
  flex-direction: flex-end;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;
  font-size: 14px;
  -webkit-box-shadow: -6px 7px 19px -4px #000000;
  box-shadow: -6px 7px 19px -4px #000000;
  font-family: "Montserrat", sans-serif;
  color: black;
  font-weight: 300;
  letter-spacing: 1px;
  padding-top: 10px;
  margin-bottom: 5px;
  margin-right:10px;
  margin-left:10px;
  transition: 500ms;
  opacity: 0.97;
  border-radius: 5px;
  height: 250px;
  cursor: pointer;
  &:hover {
    transition: 100ms;
    -webkit-box-shadow: -0px 0px 0px 0px #000000;
    box-shadow: -0px 0px 0px -0px #000000;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;
const Container = styled(motion.div)`
  display: flex;
  max-width: 1000px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-bottom:10px;
  margin-top: 10px;

  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
    height: auto;
    flex-direction: column;
    padding: 10px;
    max-width: 400px;
    justify-content: center;
    align-items: center;
  }
`;
const EmptyContainer = styled.div`
width:250px;
`
const Wrapper = styled(motion.div)`
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 50px);
  background: rgb(228, 193, 176);
  background: radial-gradient(
    circle,
    rgba(228, 193, 176, 0.6703723725818452) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export default Gallery;
